
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import Products from "../api";
  import LzEditorInput from "@/components/EditorInput.vue";
  import { Product, ProductForm } from "../api/types";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class ShopCreate extends Vue {
    @auth.State("id")
    public memberId!: string;

    productId: string | null = null;
    showDeleteModal = false;

    loaded = false;
    statusOptions = {
      enabled: this.$t("projects.create.form.status.options.enabled"),
      disabled: this.$t("projects.create.form.status.options.disabled")
    };
    productForm: ProductForm = {
      member_id: this.memberId,
      status: "disabled",
      default_img: "",
      title: "",
      description: "",
      stock: "",
      delivery_time: "",
      discount: "",
      price: "",
      images: ""
    };

    async mounted() {
      this.productId = this.$route.params.productId;
      if (!this.productId) {
        this.loaded = true;
        return;
      }

      const product = await Products.getById(this.productId);

      this.productForm = {
        member_id: this.memberId,
        status: product.active ? "enabled" : "disabled",
        default_img: [{ url: product.default_img }],
        title: product.title,
        description: product.description,
        stock: product.stock.toString(),
        delivery_time: product.delivery_time,
        discount: product.discount.toString(),
        price: product.price.toString(),
        images: product.images.map(i => ({ url: i }))
      };

      this.loaded = true;
    }

    async onSubmit(form: ProductForm) {
      const product: Omit<Product, "id"> = {
        title: form.title,
        description: form.description,
        price: Number(form.price),
        delivery_time: form.delivery_time,
        discount: Number(form.discount),
        stock: Number(form.stock),
        member_id: this.memberId,
        active: form.status === "enabled",
        default_img: form.default_img ? form.default_img[0].url : "",
        images: form.images ? form.images.map(i => i.url) : []
      };

      if (!this.productId) {
        Products.create(product)
          .then(() => {
            this.$notify({
              type: "success",
              text: this.$tc("shop.create.notifications.productSaved")
            });
            this.$router.push({ name: "shopRead" });
          })
          .catch(() => {
            this.$notify({
              type: "error",
              text: this.$tc("common.error.generic")
            });
          });
      } else {
        Products.update(this.productId, product)
          .then(() => {
            this.$notify({
              type: "success",
              text: this.$tc("shop.create.notifications.productUpdated")
            });
            this.$router.push({ name: "shopRead" });
          })
          .catch(() => {
            this.$notify({
              type: "error",
              text: this.$tc("common.error.generic")
            });
          });
      }
    }

    onCreateCancel() {
      this.$router.push({ name: "shopRead" });
    }

    confirmDeleteProduct() {
      this.showDeleteModal = true;
    }

    deleteProduct() {
      if (!this.productId) {
        return;
      }

      Products.delete(this.productId)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("shop.create.notifications.productDeleted")
          });
          this.$router.push({ name: "shopRead" });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
    }
  }
